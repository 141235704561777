*
{
    margin: 0;
    padding: 0;
    touch-action: cross-slide-y;
}

html,
body
{
    scroll-behavior: smooth;
    height: 100vh;
    font-family:Roboto, Arial, Helvetica, sans-serif;
    background-image: linear-gradient(#2ecc71 0%, #3498db 100%);
    background-size: 100vw 110vh;
}

body{
    overflow-x:hidden;
}

.topnav{
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
    height: 75px;
    width: 100%;
    background-image: linear-gradient(30deg, #1abc9c 0%, #2980b9 100%);
    justify-content: center;
    align-items: center;
    transition: top 0.3s;
    box-shadow: 0px 5px 10px #2c3e50;
}
  
.topnav a {
    margin-left: 50px;
    margin-right: 50px;
    color: #ecf0f1;
    text-align: center;
    width: auto;
    height: 50px;
    text-decoration: none;
    font-size: 30px;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 5px;
}

.topnav .image-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.topnav .image{
    margin-left: 100px;
    margin-right: 100px;
    width: 150px;
    height: 75px;
    background-color: none;
    background-size: 150px 100%;
    background-image: url('../static/textures/logo.png');
    padding-top: 0;
    border-radius: 10px;
    transition: all 0.5s;
}

.topnav .expandPic{
    transition: all 0.5;
}

.topnav .image:hover{
    transition: all 0.5s;
    background-color: rgba(46, 204, 113,0.5);
}

.topnav div.undA{
    position: relative;
    top: 0;
    height: 10px;
    width: 84px;
    background-color: none;
    transition: all 0.2s;
    transform: translate(0, -55px);
}

.topnav div.undA::before{
    content: "";
    position: fixed;
    width: 84px;
    height: 75px;
    background-color: none;
    z-index: 100;
    transform: translate(-50%, 0);
}

.topnav div.undA:hover{
    transition: all 0.2s;
    background-color: #ecf0f1;
}

.topnav div.undB{
    transform: translate(0, -55px);
    position: relative;
    top: 0;
    height: 10px;
    width: 140px;
    background-color: none;
    transition: all 0.2s;
}

.topnav div.undB::before{
    content: "";
    position: fixed;
    width: 140px;
    height: 75px;
    background-color: none;
    z-index: 100;
    transform: translate(-50%, 0);
}

.topnav div.undB:hover{
    transition: all 0.2s;
    background-color: #ecf0f1;
}

.topnav div.undC{
    transform: translate(0, -55px);
    position: relative;
    top: 0;
    height: 10px;
    width: 95px;
    background-color: none;
    transition: all 0.2s;
}

.topnav div.undC::before{
    content: "";
    position: fixed;
    width: 95px;
    height: 75px;
    background-color: none;
    z-index: 100;
    transform: translate(-50%, 0);
}

.topnav div.undC:hover{
    transition: all 0.2s;
    background-color: #ecf0f1;
}

.webgl
{
    touch-action: none;
    margin-left: auto;
    margin-right: auto;
    outline: none;
}

.facebook{
    width: 100%;
    position: absolute;
    top: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.facebook .bannerDiv{
    display: flex;
    justify-content: center;
    align-content: center;
    transition: all 0.5s;
    width: 50vw;
}

.facebook .bannerLink{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-content: center;
    color: black; 
    transition: all 0.5s;
    width: 50%;
}

.facebook .bannerLink:hover{
    transition: all 0.5s;
    width: 80%;
    box-shadow: 0px 10px 20px rgba(52, 73, 94, 0.4);
}

.facebook .banner{
    transition: all 0.5s;
    border-radius: 20px; 
    background-image: linear-gradient(90deg, #2ecc71, 50%, #27ae60 100%);
    width: 100%; 
    display: flex; 
    height: 50px; 
    font-size: 35px; 
    justify-content: center; 
    align-items: center;
    box-shadow: 0px 10px 15px rgba(52, 73, 94, 0.4);
    margin-right: 5px;
}

@keyframes fadeoutAni{
    0% {opacity: 100%;}
    100% {opacity: 0%;}
}

@keyframes fadeinAni{
    0% {opacity: 0%;}
    100% {opacity: 100%;}
}

.fadeout {
    animation-name: fadeoutAni; 
    animation-duration: 250ms;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
}

.fadein {
    animation-name: fadeinAni; 
    animation-duration: 250ms;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
}

.facebook .banner2{
    transition: all 0.5s;
    border-radius: 20px; 
    background-image: linear-gradient(-90deg, #1abc9c, 50%, #16a085 100%);
    width: 100%; 
    display: flex; 
    height: 50px; 
    font-size: 35px; 
    justify-content: center; 
    align-items: center;
    box-shadow: 0px 10px 15px rgba(52, 73, 94, 0.4);
    margin-left: 5px;
}

.wave-divider{
    z-index: 50;
    position: absolute;
    overflow-x: hidden;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: translate(0px, -46px);
}

.wave-divider svg {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
}

.wave-divider .shape-fill {
    fill: #1abc9c;
}

.aboutus{
    color:#ecf0f1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    background-image: linear-gradient(#1abc9c 96%, #38a6f0 100%);
    text-align: center;
    padding-top: 20px;
    padding-bottom: 150px;
}

.aboutus .center{
    width: 50%;
}

.aboutus .intro{
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutus .aboutFLL{
    background-color: #2980b9;
    background-image: linear-gradient(-45deg, #2980b9 10%, #1abc9c 100%);
    box-shadow: 0px 10px 15px rgba(52, 73, 94, 0.4);
    border-radius: 50px;
    font-size: 25px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.aboutus .members{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.aboutus .members .member{
    height: 650px;
    font-size: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #3498db; */
    background-image: linear-gradient(#2980b9 80%, #1abc9c 100%);
    padding: 10px;
    width: 30%;
    border-radius: 10px;
    margin: 10px;
}

.aboutus .members .member p{
    border-bottom: 5px solid #1abc9c;
}

.wave-divider-2 {
    position: absolute;
    padding-bottom: -20px;
    top: 0;
    left: 0;
    width: 200%;
    overflow-x: hidden;
    line-height: 0;
    transform: translate(0%, -96%);
    z-index: 50;
}

.wave-divider-2 svg {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
}

.wave-divider-2 .shape-fill {
    fill: #3498db;
}

.blog{
    transition: flex-grow 1s;
    font-size: 30px;
    z-index: 50;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(#3498db 80%, #328ac5 100%);
    justify-content: center;
    align-items: center;
    /* padding-bottom: 100px; */
    margin-left: auto;
    margin-right: auto;
}

.blog .hoverBorder{
    transition: flex-grow 1s;
    font-size: 30px;
    z-index: 50;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(#3498db 80%, #328ac5 100%);
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
}

.blog .post{
    transition: all 0.5s;
    font-size: 20px;
    color: #ecf0f1;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    width: 50%;
    border: 5px solid #1abc9c;
    background-image: linear-gradient(#1abc9c, #16a085);
    margin-top: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 10px 15px rgba(52, 73, 94, 0.2);
}

.blog .hoverBorder :hover{
    padding-left: 20px;
    padding-right: 20px;
}

.blog .post :hover{
    padding-left: 0px;
    padding-right: 0px;
}

.wave-divider-2 :hover{
    padding-left: 0px;
    padding-right: 0px;
}

.blog .post .content{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog .post .content p{
    z-index: 10;
    margin-top: 10px;
    margin-bottom: 10px;
}
.blog .post .content div{
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wave-divider-3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    overflow-x: hidden;
    line-height: 0;
    transform: translate(0%, -96%);
    z-index: 50;
}

.wave-divider-3 svg {
    position: relative;
    display: block;
    width: 100%;
    height: 75px;
}

.wave-divider-3 .shape-fill {
    fill: #2980b9;
}

.contact{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(#2980b9 0%, #3498db 100%);
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
}
.contact h1{
    color: #ecf0f1;
    margin-bottom: 20px;
}

.contact .form{
    background-color: #2980b9;
    background-image: linear-gradient(-45deg, #2980b9 10%, #1abc9c 100%);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 10px 15px rgba(52, 73, 94, 0.4);
}

.contact .form .email{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10px;
}

.contact .form .subroot{
    display: flex;
    flex-direction: row;
}

.contact .form .email .label{
    color: #ecf0f1;
    font-size: 20px;
}

.contact .form input{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    color: #2c3e50;
    height: 30px;
    width: 98%;
    border-radius: 5px;
    text-align: center;
    background-color: #ecf0f1;
    border: 5px solid #ecf0f1;
}

.contact .form .email .content{
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #2c3e50;
    height: 113px;
    width: 98%;
    border-radius: 5px;
    background-color: #ecf0f1;
    border: 5px solid #ecf0f1;
}

.footer{
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-direction: row;
    /* background-color: #2980b9; */
    background-image: linear-gradient(#38afff 0%, #4eb8ff 100%);
    width: 100%;
    height: 200px;
    align-items: center;
    transform: translate(0, -20px);
    padding-bottom: 25px;
}

.footer .logo-and-social{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    color: white;
}

.footer .logo-and-social .media-selector{
    display: flex;
    width: 100%;
}

.footer .sponsors{
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .sponsors .text{
    height: 100px;
    margin-right: 20px;
}

.footer .sponsors .sponsor{
    transition: all 0.3s;
    border-radius: 20px;
}

.footer .sponsors .sponsor:hover{
    background-color: rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 2000px) {
    [class*="wave-divider-"] {
      width: 100%;
    }

    .topnav div.undA{
        transform: translate(0, -47px);
    }

    .topnav div.undB{
        transform: translate(0, -47px);
    }

    .topnav div.undC{
        transform: translate(0, -47px);
    }

    .topnav div.undA::before{
        height: 50px;
    }

    .topnav div.undB::before{
        height: 50px;
    }

    .topnav div.undC::before{
        height: 50px;
    }

    .topnav{
        height: 50px;
    }

    .topnav a{
        height: 50px;
    }

    .topnav .image{
        height: 50px;
        width: 125px;
        background-image: url("../static/textures/keplerMini.png");
        margin-left: 0;
        margin-right: 50px;
    }

    .facebook{
        top: 100px;
        position: absolute;
    }

    .aboutus .members .member{
        width: 70%;
    }

    .aboutus .center{
        width: 90%;
    }

    .blog .post{
        width: 60%;
    }

    .contact .form .subroot{
        flex-direction: column;
    }
}

@media only screen and (max-width: 1770px) {
    .facebook .bannerDiv{
        width: 75vw;
    }

    .footer{
        flex-direction: column;
        height: 355px;
    }

    .footer .logo-and-social{
        width: 90%;
        margin-top: 20px;
        margin-left: 0;
        align-items: center;
    }

    .footer .logo-and-social .media-selector{
        justify-content: center;
    }

    .footer .sponsors{
        width: 90%;
        flex-direction: column;
    }

    .footer .sponsors .text{
        height: auto;
        margin-right: 0;
        margin-top: 30px;
    }

    .footer{
        height:620px;
    }
}

@media only screen and (max-width: 1000px) {
    /* For mobile phones: */
    .facebook .bannerDiv{
        width: 100vw;
    }

    .facebook .bannerLink:hover{
        width: 60%;
    }

    [class*="wave-divider-"] {
      width: 100%;
    }

    .blog .post .content div{
        flex-direction: column;
    }

    .topnav{
        flex-direction: column;
        height: auto;
    }

    .topnav .image-container{
        width: 100%;
    }

    .topnav .image{
        margin-left: 50%;
        transform: translateX(-50%);
        margin-right: 0;
    }

    .aboutus .members{
        flex-direction: column;
    }

    .aboutus .members .member{
        width: 90%;
    }

    .aboutus .center{
        width: 90%;
    }

    .aboutus .intro{
        width: 100%;
    }

    .blog .post{
        width: 90%;
    }

    .contact .form .subroot{
        flex-direction: column;
    }

    .facebook {
        top: 85px;
    }
}

@media only screen and (max-width: 525px) {
    .facebook .bannerDiv{
        flex-direction: column;
        align-items: center;
        width: 75vw;
    }

    .facebook .bannerLink{
        width: 90%;
    }

    .facebook .bannerLink:hover{
        width: 100%;
    }

    .facebook .banner{
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .facebook .banner2{
        margin-left: 0px;
    }

    .topnav .image{
        margin-left: 50px;
        transform: translate(0, 0);
    }

    .footer{
        height:630px;
    }
}